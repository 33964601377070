export default function authHeaderFormData() {
    let user = JSON.parse(localStorage.getItem('user'))

    if(user && user.token) {
        let header = { Authorization: `Bearer ${user.token}` }
            
        return header
    } else {
        return {}
    }
}