import authHeader from './auth-header'
import authHeaderFormData from './auth-header-form-data'
import proj4 from 'proj4'

const API_BASE = process.env.VUE_APP_API_URL
const HUBEAU_BASE = process.env.VUE_APP_HUBEAU_URL

class SensorService {
    async getSensors(type, source) {
      let responseCapteur = await fetch(`${API_BASE}/Capteur${type ? '/' + type : ''}${source ? '?source=' + source : ''}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseCapteur && responseCapteur.ok && responseCapteur.status !== 204) {
        let capteurs = await responseCapteur.json()
        return capteurs
      } else if (responseCapteur.status === 204){
        return []
      } else {
          throw new Error(responseCapteur.statusText)
      }
    }

    async getHubeauHydroSensors() {
      let responseHubEau = await fetch(`${HUBEAU_BASE}/hydrometrie/referentiel/stations?en_service=true&fields=libelle_station,code_station,coordonnee_x_station,coordonnee_y_station&format=json&bbox=-9.86%2C41.15%2C10.38%2C51.56&size=5000`, {
        method: 'GET'
      })

      let geojson = []

      if(responseHubEau && responseHubEau.ok) {
        let data = await responseHubEau.json()

        for(var d of data.data) {

          var proj_l93 = "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
          var proj_wgs84 = "+proj=longlat +datum=WGS84 +no_defs"

          var coords_wgs84 = proj4(proj_l93, proj_wgs84, [d.coordonnee_x_station, d.coordonnee_y_station])

          geojson.push({
            type: 'Feature',
            properties: {
              'libelle_station': d.libelle_station,
              'code_station': d.code_station,
            },
            geometry: {
              type: 'Point',
              coordinates: coords_wgs84
            }
          })
        }

        return geojson
      } else {
        throw new Error(responseHubEau.statusText)
      }
    }

    async getSensorsTypes() {
        let responseType = await fetch(`${API_BASE}/Capteur/Types`, {
          method: 'GET',
          headers: authHeader()
        })
  
        if(responseType && responseType.ok) {
          let types = await responseType.json()
          return types
        } else {
            throw new Error(responseType.statusText)
        }
    }

    async getSensorData(type, identifier, dateStart, realtime=null) {
      let url = `${API_BASE}/Capteur/${type}/${encodeURIComponent(identifier)}/data${dateStart ? '?startDate=' + dateStart.toISOString() : ''}${realtime!=null ? '&realtime=' + realtime : ''}`;
      let responseData = await fetch(url, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseData && responseData.ok) {
        let data = await responseData.json()

        return data
      } else {
        throw new Error(responseData.statusText)
      }
    }

    async getOnlySensorData(type, identifier, dateStart, realtime=null) {
      let data = await this.getSensorData(type, identifier, dateStart, realtime)
      let d = data.data.serie.map(s => { return { 'x': new Date(s.date), 'y': s.value } })

      return d
    }

    async getOnlyRealTimePiezoData(identifier, dateStart) {
      let responseRealTimeData = await fetch(`${API_BASE}/Capteur/Piezo/${encodeURIComponent(identifier)}/data/realtime?startDate=${dateStart.toISOString()}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseRealTimeData && responseRealTimeData.ok) {
        let data = await responseRealTimeData.json()
        let d = data.map(s => { return { 'x': new Date(s.date), 'y': s.value } })
        return d
      } else {
        throw new Error(responseRealTimeData.statusText)
      }
    }

    async getSensorSources() {
      let responseSource = await fetch(`${API_BASE}/Capteur/Sources`, {
        method: 'GET',
        headers: authHeader()
      })
  
      if(responseSource && responseSource.ok) {
        let sources = await responseSource.json()
        return sources
      } else {
          throw new Error(responseSource.statusText)
      }
    }

    async createSensor(sensor) {
      let responseSensor = await fetch(`${API_BASE}/Capteur/Create`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(sensor)
      })

      if(responseSensor && responseSensor.ok) {
        let resp = await responseSensor.json()
        return Promise.resolve(resp)
      }
      else {
        let resp = await responseSensor.json()
        return Promise.reject(resp)
      }
    }

    async postDataFile(identifier, type, content) {
      var formData = new FormData()
      var blob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
      formData.append('data', blob, 'data.csv')

      let responseData = await fetch(`${API_BASE}/Capteur/${type}/${encodeURIComponent(identifier)}/data.csv`, {
        method: 'POST',
        headers: authHeaderFormData(),
        body: formData
      })

      if(responseData && responseData.ok) {
        let resp = await responseData.json()
        return Promise.resolve(resp)
      } else {
        let resp = await responseData.json()
        return Promise.reject(resp)
      }
    }

    async getDataFile(identifier, type) {
      let responseData = await fetch(`${API_BASE}/Capteur/${encodeURIComponent(type)}/${encodeURIComponent(identifier)}/data.csv`, {
        method: 'GET',
        headers: authHeader()
      })
  
      if(responseData && responseData.ok) {
        let data = await responseData.text()

        return data
      } else {
          throw new Error(responseData.statusText)
      }
    }

    async getSeuilsPiezo(identifier) {
      let responseSeuils = await fetch(`${API_BASE}/Capteur/Piezo/${encodeURIComponent(identifier)}/seuils`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseSeuils && responseSeuils.ok) {
        let seuils = await responseSeuils.json()
        return seuils
      } else {
          throw new Error(responseSeuils.statusText)
      }
    }

    async createSeuilsPiezo(identifier, seuils) {
      let responseCreate = await fetch(`${API_BASE}/Capteur/Piezo/${encodeURIComponent(identifier)}/seuils`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(seuils)
      })

      if(responseCreate && responseCreate.ok) {
        let newSeuils = await responseCreate.json()

        return Promise.resolve(newSeuils)
      } else {
        let error = await responseCreate.json()
        return Promise.reject(error)
      }
    }

    async createSeuilsDebit(identifier, seuils) {
      let responseCreate = await fetch(`${API_BASE}/Capteur/Debit/${encodeURIComponent(identifier)}/seuils`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(seuils)
      })

      if(responseCreate && responseCreate.ok) {
        let newSeuils = await responseCreate.json()

        return Promise.resolve(newSeuils)
      } else {
        let error = await responseCreate.json()
        return Promise.reject(error)
      }
    }

    async getSeuilsDebit(identifier) {
      let responseSeuils = await fetch(`${API_BASE}/Capteur/Debit/${encodeURIComponent(identifier)}/seuils`, {
        method: 'GET',
        headers: authHeader()
      })
  
      if(responseSeuils && responseSeuils.ok) {
        let seuils = await responseSeuils.json()

        return seuils
      } else {
          throw new Error(responseSeuils.statusText)
      }
    }

    async getNearestCapteur(type, source, x, y) {
      let responseNearest = await fetch(`${API_BASE}/Capteur/near?longitude=${x}&latitude=${y}&source=${encodeURIComponent(source)}&type=${encodeURIComponent(type)}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseNearest && responseNearest.ok) {
        let nearest = await responseNearest.json()

        return Promise.resolve(nearest)
      } else {
        let error = await responseNearest.json()
        return Promise.reject(error)
      }
    }

    async getLatestSensorData(type, identifier) {
      let responseLatest = await fetch(`${API_BASE}/Capteur/${encodeURIComponent(type)}/${encodeURIComponent(identifier)}/data/latest`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseLatest && responseLatest.ok) {
        let nearest = await responseLatest.json()

        return Promise.resolve(nearest)
      } else {
        let error = await responseLatest.json()
        return Promise.reject(error)
      }
    }

    async getSensorDataSummary(type, identifier) {
      let responseSummary = await fetch(`${API_BASE}/Capteur/${encodeURIComponent(type)}/${encodeURIComponent(identifier)}/data/summary`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseSummary && responseSummary.ok) {
        let summary = await responseSummary.json()

        return Promise.resolve(summary)
      } else {
        let error = await responseSummary.json()
        return Promise.reject(error)
      }
    }

    async getRealTimePiezoData(identifier, dateStart) {
      let responseLatest = await fetch(`${API_BASE}/Capteur/piezo/${encodeURIComponent(identifier)}/data/realtime?startDate=${new Date(dateStart).toISOString()}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseLatest && responseLatest.ok) {
        let nearest = await responseLatest.json()

        return Promise.resolve(nearest)
      } else {
        let error = await responseLatest.json()
        return Promise.reject(error)
      }
    }

    async getIpsChartData(identifier) {
      let today = new Date()
      let endDate = `${today.getDate() < 10 ? '0' + today.getDate() : today.getDate()}/${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}/${today.getFullYear()}`

      let responseIpsChart = await fetch(`https://ades.eaufrance.fr/Fiche/MesureGraphSpli?CodeBSS=${encodeURIComponent(identifier)}&DateDebut=${encodeURIComponent('01/01/1900')}&DateFin=${encodeURIComponent(endDate)}&Couple=3`, {
        method: 'GET'
      })

      if(responseIpsChart && responseIpsChart.ok) {
        let rawChartData = await responseIpsChart.json()

        if(rawChartData) {
          try {
            let dix_ans_sec = JSON.parse(`[${rawChartData.dix_ans_sec.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let cinq_ans_sec = JSON.parse(`[${rawChartData.cinq_ans_sec.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let deux_ans_sec = JSON.parse(`[${rawChartData.deux_ans_sec.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
          //  let mediane = JSON.parse(`[${rawChartData.mediane.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let deux_ans_hum = JSON.parse(`[${rawChartData.deux_ans_hum.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let cinq_ans_hum = JSON.parse(`[${rawChartData.cinq_ans_hum.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let dix_ans_hum = JSON.parse(`[${rawChartData.dix_ans_hum.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)
            let sup_dix_ans_hum = JSON.parse(`[${rawChartData.sup_dix_ans_hum.replace(/Date\.UTC\(\d{4},(\d+),\d+\)/gi, "$1").replace(/([a-zA-Z]+) ?:/gi, '"$1":').replace(/'/gi, '"')}]`)

            return Promise.resolve({
              dix_ans_sec: dix_ans_sec.map(d => { return { x: d.x + 1, y: d.y }; }),
              cinq_ans_sec: cinq_ans_sec.map(d => { return { x: d.x + 1, y: d.y }; }),
              deux_ans_sec: deux_ans_sec.map(d => { return { x: d.x + 1, y: d.y }; }),
           //   mediane: mediane.map(d => { return { x: d.x + 1, y: d.y }; }),
              deux_ans_hum: deux_ans_hum.map(d => { return { x: d.x + 1, y: d.y }; }),
              cinq_ans_hum: cinq_ans_hum.map(d => { return { x: d.x + 1, y: d.y }; }),
              dix_ans_hum: dix_ans_hum.map(d => { return { x: d.x + 1, y: d.y }; }),
              sup_dix_ans_hum: sup_dix_ans_hum.map(d => { return { x: d.x + 1, y: d.y }; })
            })
          } catch(e) {
            console.warn(e)
            return Promise.reject(`Impossible d'interpréter les coupes IPS pour le piézomètre ${identifier}`)
          }
        }
      }
    }
}

export default new SensorService()
