import authHeader from './auth-header'
import authHeaderFormData from "@/services/auth-header-form-data";

const API_BASE = process.env.VUE_APP_API_URL

class ModelService {
    async getModel(id) {
      let responseModel = await fetch(`${API_BASE}/Model${id ? '/' + id : ''}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseModel && responseModel.ok) {
          return await responseModel.json()
      } else {
          throw new Error(responseModel.statusText)
      }
    }

    async getLatestModelResult(id) {
      let responseModelResult = await fetch(`${API_BASE}/Model/${id}/results/latest`, {
        method: 'GET',
        headers: authHeader()
      })
      if(responseModelResult && responseModelResult.ok) {
        try{
          return await responseModelResult.json()
        }
        catch{
          return null
        }
      } else {
          throw new Error(responseModelResult.statusText)
      }
    }

    async getSpecificModelResult(id, resultId) {
      let responseModelResult = await fetch(`${API_BASE}/Model/${id}/results/${resultId}`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseModelResult && responseModelResult.ok) {
        return await responseModelResult.json()
      } else {
          throw new Error(responseModelResult.statusText)
      }
    }

    async getAvailableModelResult(id) {
      let responseModelResult = await fetch(`${API_BASE}/Model/${id}/results`, {
        method: 'GET',
        headers: authHeader()
      })
      if(responseModelResult && responseModelResult.ok) {
        return await responseModelResult.json()
      } else {
          throw new Error(responseModelResult.statusText)
      }
    }

    async getIPS(bssCode, date, scenario) {

        let uri = `${API_BASE}/Model/IPS/${encodeURIComponent(bssCode)}`

        if(date && scenario) {
          uri = `${API_BASE}/Model/IPS/${encodeURIComponent(bssCode)}/${date}/${encodeURIComponent(scenario)}`
        }

        let responseIPS = await fetch(uri, {
            method: 'GET',
            headers: authHeader()
        })

        if(responseIPS && responseIPS.ok){
            return await responseIPS.json()
        } else {
            throw new Error(responseIPS.statusText)
        }
    }

    async getAllIPS(bssCodes, date, scenario) {

        let uri = `${API_BASE}/Model/IPS`

        if(date && scenario) {
          uri = `${API_BASE}/Model/IPS/${date}/${encodeURIComponent(scenario)}`
        }

        let responseAllIPS = await fetch(uri, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(bssCodes)
        })

        if(responseAllIPS && responseAllIPS.ok){
            return await responseAllIPS.json()
        } else {
            throw new Error(responseAllIPS.statusText)
        }
    }

    async getBSH(id) {
      let uri = `${API_BASE}/Model/BSH/${encodeURIComponent(id)}`

      let responseBSH = await fetch(uri, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseBSH && responseBSH.ok) {
        return await responseBSH.json()
      } else {
        throw new Error(responseBSH.statusText)
      }
    }

    async getAllBSH(date) {
      if(!date)
        date = new Date().toISOString().substr(0, 10)

      let uri = `${API_BASE}/Model/BSH?date=${date}`

      let responseBSH = await fetch(uri, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseBSH && responseBSH.ok) {
        return await responseBSH.json()
      } else {
        throw new Error(responseBSH.statusText)
      }
    }

    async getBSHGeometry(id, simplifyAmount) {
      let uri = `${API_BASE}/Model/BSH/${encodeURIComponent(id)}/geom?simplify=${encodeURIComponent(simplifyAmount)}`

      let responseBSHGeom = await fetch(uri, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseBSHGeom && responseBSHGeom.ok) {
        return await responseBSHGeom.json()
      } else {
        throw new Error(responseBSHGeom.statusText)
      }
    }

    async createBSH(request) {
      let uri = `${API_BASE}/Model/BSH/create`

      let responseCreateBSH = await fetch(uri, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseCreateBSH && responseCreateBSH.ok) {
        let bsh = await responseCreateBSH.json()
        return Promise.resolve(bsh)
      } else {
        let error = await responseCreateBSH.json()
        return Promise.reject(error)
      }
    }

    async updateBSH(id, request) {
        let uri = `${API_BASE}/Model/BSH/${id}`

        let responseUpdateBSH = await fetch(uri, {
            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify(request)
        })

        if(responseUpdateBSH && responseUpdateBSH.ok) {
            return Promise.resolve()
        } else {
            let error = await responseUpdateBSH.json()
            return Promise.reject(error)
        }
    }

    async deleteBSH(id) {
      let uri = `${API_BASE}/Model/BSH/${id}`

      let responseDeleteBSH = await fetch(uri, {
        method: 'DELETE',
        headers: authHeader()
      })

      if(responseDeleteBSH && responseDeleteBSH.ok) {
        return Promise.resolve()
      } else {
        let error = await responseDeleteBSH.json()
        return Promise.reject(error)
      }
    }

    async computeBSH(id, date) {
        if(!date)
            date = new Date().toISOString().substr(0, 10)

      let uri = `${API_BASE}/Model/BSH/${encodeURIComponent(id)}/compute?date=${date}`

      let responseBSHCompute = await fetch(uri, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseBSHCompute && responseBSHCompute.ok) {
        let bsh = await responseBSHCompute.json()
        return Promise.resolve(bsh)
      } else {
        let error = await responseBSHCompute.json()
        return Promise.reject(error)
      }
    }

    async computeAndDownloadAllBSH(date) {
      let uri = `${API_BASE}/Model/BSH/compute/rapportBSH?date=${date}`

      var header = authHeader()
      header['Content-Type'] = 'application/zip; charset=utf-8'

      let responseBSHCompute = await fetch(uri, {
        method: 'GET',
        responseType: 'application/zip',
        headers: header
      })

      if(responseBSHCompute && responseBSHCompute.ok) {
        let data = await responseBSHCompute.blob()
        return data
      } else {
          return null
      }
    }

    async downloadAllBSH(dateDebut, dateFin) {
      let uri = `${API_BASE}/Model/BSH/rapportBSH?dateDebut=${dateDebut}&dateFin=${dateFin}`

      var header = authHeader()
      header['Content-Type'] = 'application/zip; charset=utf-8'

      let responseBSHCompute = await fetch(uri, {
        method: 'GET',
        responseType: 'application/zip',
        headers: header
      })


      if(responseBSHCompute && responseBSHCompute.ok) {
        let data = await responseBSHCompute.blob()
        return data
      } else {
        return null
      }
    }

    async getIPSDetails(bssCode) {
      try {
        let ips = await this.getIPS(bssCode)
        
        if(ips && ips.details) {
          let responseDetails = await fetch(ips.details.replace('services.ades.eaufrance.fr', 'adesws.brgm-rec.fr'), {
            method: 'GET'
          })

          if(responseDetails && responseDetails.ok) {
            return await responseDetails.json()
          }
        }
      } catch (e) {
        return null
      }

      return null
    }

    async downloadModelResult(modelId, resultId) {
      let responseResult = await fetch(`${API_BASE}/Model/${modelId}/results/${resultId}/export.csv`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseResult && responseResult.ok) {
        let result = await responseResult.text()
        return Promise.resolve(result)
      } else {
        let error = await responseResult.json()
        return Promise.reject(error)
      }
    }

    async computeModel(modelId, startDate, endDate, saveResult) {
      let request = {
        dateDebut: startDate,
        dateFin: endDate,
        save: saveResult
      }

      let responseCompute = await fetch(`${API_BASE}/Model/compute/${modelId}`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseCompute && responseCompute.ok) {
        let compute = await responseCompute.json()
        return Promise.resolve(compute)
      } else {
        let error = await responseCompute.json()
        return Promise.reject(error)
      }
    }

    async getAllModels() {
      let responseModels = await fetch(`${API_BASE}/Model`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseModels && responseModels.ok) {
        return await responseModels.json()
      } else {
        throw new Error(responseModels.statusText)
      }
    }

    async getAllGroupeEros() {
      let responseModels = await fetch(`${API_BASE}/Eros`, {
        method: 'GET',
        headers: authHeader()
      })

      if(responseModels && responseModels.ok) {
        return await responseModels.json()
      } else {
        throw new Error(responseModels.statusText)
      }
    }

    async createGardeniaModel(request) {
      let responseCreate = await fetch(`${API_BASE}/Model/Gardenia/Create`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseCreate && responseCreate.ok) {
        let createdModel = await responseCreate.json()
        return Promise.resolve(createdModel)
      } else {
        let error = await responseCreate.json()
        return Promise.reject(error)
      }
    }

    async createErosModel(request) {
      let responseCreate = await fetch(`${API_BASE}/Eros/Create`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseCreate && responseCreate.ok) {
        let createdModel = await responseCreate.json()
        return Promise.resolve(createdModel)
      } else {
        let error = await responseCreate.json()
        return Promise.reject(error)
      }
    }
    async createErosDebit(request) {
      let responseCreate = await fetch(`${API_BASE}/Model/ErosDebit/Create`, {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseCreate && responseCreate.ok) {
        let createdModel = await responseCreate.json()
        return Promise.resolve(createdModel)
      } else {
        let error = await responseCreate.json()
        return Promise.reject(error)
      }

    }
    async createManualModel(request) {
        let responseCreate = await fetch(`${API_BASE}/Model/Manuel/Create`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(request)
        })

        if(responseCreate && responseCreate.ok) {
            let createdModel = await responseCreate.json()
            return Promise.resolve(createdModel)
        } else {
            let error = await responseCreate.json()
            return Promise.reject(error)
        }
    }

    async updateGardeniaModel(id, request) {
      let responseUpdate = await fetch(`${API_BASE}/Model/Gardenia/${id}`, {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(request)
      })

      if(responseUpdate && responseUpdate.ok) {
        let updatedModel = await responseUpdate.json()
        return Promise.resolve(updatedModel)
      } else {
        let error = await responseUpdate.json()
        return Promise.reject(error)
      }
    }

    async updateManualModel(id, request) {
        let responseUpdate = await fetch(`${API_BASE}/Model/Manuel/${id}`, {
            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify(request)
        })

        if(responseUpdate && responseUpdate.ok) {
            let updatedModel = await responseUpdate.json()
            return Promise.resolve(updatedModel)
        } else {
            let error = await responseUpdate.json()
            return Promise.reject(error)
        }
    }
    async updateErosModel(id, request) {
      let responseUpdate = await fetch(`${API_BASE}/Eros/${id}`, {
          method: 'PATCH',
          headers: authHeader(),
          body: JSON.stringify(request)
      })

      if(responseUpdate && responseUpdate.ok) {
          let updatedModel = await responseUpdate.json()
          return Promise.resolve(updatedModel)
      } else {
          let error = await responseUpdate.json()
          return Promise.reject(error)
      }
  }
  async updateErosDebit(id, request) {
    let responseUpdate = await fetch(`${API_BASE}/Model/ErosDebit/${id}`, {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(request)
    })

    if(responseUpdate && responseUpdate.ok) {
        let updatedModel = await responseUpdate.json()
        return Promise.resolve(updatedModel)
    } else {
        let error = await responseUpdate.json()
        return Promise.reject(error)
    }
}

    async importManualModelData(id, csvContent) {
        var formData = new FormData()
        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        formData.append('csvResult', blob, 'data.csv')

        let responseData = await fetch(`${API_BASE}/Model/Manuel/${id}/result`, {
            method: 'POST',
            headers: authHeaderFormData(),
            body: formData
        })

        if(responseData && responseData.ok) {
            let resp = await responseData.json()
            return Promise.resolve(resp)
        } else {
            let resp = await responseData.json()
            return Promise.reject(resp)
        }
    }
}

export default new ModelService()